<template>
  <v-card class="mb-4">
    <v-card-text class="border-b">
      <block-title title="Данные для печати"></block-title>
    </v-card-text>
    <v-card-text class="border-b">
      <v-row dense>
        <v-col cols="auto">
          <v-chip
            class="tw-font-medium"
            :class="{ 'bg-primary': isActiveTemplate === 1 }"
            label
            @click="setStandartTemplate"
          >
            Стандартная
          </v-chip>
        </v-col>
        <v-col cols="auto">
          <v-chip
            class="tw-font-medium"
            :class="{ 'bg-primary': isActiveTemplate === 2 }"
            label
            @click="setStudentTemplate"
          >
            ОКМУ
          </v-chip>
        </v-col>
        <v-col cols="auto">
          <v-chip
            class="tw-font-medium"
            :class="{ 'bg-primary': isActiveTemplate === 3 }"
            label
            @click="setCollegePrintTemplate"
          >
            Колледж
          </v-chip>
        </v-col>
        <v-col cols="auto">
          <v-chip
            class="tw-font-medium"
            :class="{ 'bg-primary': isActiveTemplate === 4 }"
            label
            @click="setOKMPUPrintTemplate"
          >
            ОКМПУ
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="border-b">
      <v-text-field
        v-for="(field, i) in templateFields"
        v-model="modelFields[i][field]"
        class="mb-4 tw-font-medium"
        :label="activeTemplate[field].label"
        density="compact"
        hide-details="auto"
        variant="outlined"
        :key="i"
      >
      </v-text-field>
    </v-card-text>
    <v-card-text>
      <a-button
        color="purple"
        block
        @click="printCard"
        :loading="isPrintingLoading"
        :disabled="isPrintingLoading"
        ><span class="material-symbols-outlined">print</span>Печать</a-button
      >
    </v-card-text>
  </v-card>
</template>

<script setup>
import BlockTitle from "./BlockTitle.vue"
import { ref, toRaw, watch, defineProps, computed } from "vue"
import standartPrintTemplate from "@/modules/cardwork/utils/standart-print-template"
import studentPrintTemplate from "@/modules/cardwork/utils/student-print-template"
import collegePrintTemplate from "@/modules/cardwork/utils/college-print-template"
import okmpuCollegePrintTemplate from "@/modules/cardwork/utils/okmpu-college-print-template"
import { generatePrint } from "@/utils/print-helper"

const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
})
const activeTemplate = ref(standartPrintTemplate)
const isActiveTemplate = ref(1)
const isPrintingLoading = ref(false)

const templateFields = computed(() => {
  return Object.keys(activeTemplate.value).filter((key) => {
    const type = activeTemplate.value[key].type
    // не отображаем поле ввода для типа 'label'
    return type !== "label"
  })
})
const modelFields = ref(
  templateFields.value.map((item) => {
    return {
      [item]: props.card[item] ? props.card[item] : "",
    }
  })
)

watch(
  () => props.card,
  () => {
    modelFields.value = templateFields.value.map((item) => {
      return {
        [item]: props.card[item] ? props.card[item] : "",
      }
    })
  }
)

const setStandartTemplate = () => {
  isActiveTemplate.value = 1
  activeTemplate.value = standartPrintTemplate
  modelFields.value = templateFields.value.map((item) => {
    return {
      [item]: props.card[item] ? props.card[item] : "",
    }
  })
}

const setStudentTemplate = () => {
  isActiveTemplate.value = 2
  activeTemplate.value = studentPrintTemplate
  modelFields.value = templateFields.value.map((item) => {
    return {
      [item]: props.card[item] ? props.card[item] : "",
    }
  })
}
const setCollegePrintTemplate = () => {
  isActiveTemplate.value = 3
  activeTemplate.value = collegePrintTemplate
  modelFields.value = templateFields.value.map((item) => {
    return {
      [item]: props.card[item] ? props.card[item] : "",
    }
  })
}
const setOKMPUPrintTemplate = () => {
  isActiveTemplate.value = 4
  activeTemplate.value = okmpuCollegePrintTemplate
  modelFields.value = templateFields.value.map((item) => {
    return {
      [item]: props.card[item] ? props.card[item] : "",
    }
  })
}

const convertArrayToObject = (arr) => {
  let result = {}
  for (let i = 0; i < arr.length; i++) {
    let key = Object.keys(arr[i])[0]
    let value = arr[i][key]
    result[key] = value
  }
  return result
}

const printCard = async () => {
  try {
    isPrintingLoading.value = true
    const outputStudentPDF = await generatePrint(
      convertArrayToObject(toRaw(modelFields.value)),
      toRaw(activeTemplate.value)
    )
    window.open(outputStudentPDF, "_blank")
  } catch (error) {
    console.log(error)
  } finally {
    isPrintingLoading.value = false
  }
}
</script>
