export default {
  cardID: {
    type: "text",
    label: "ID карты",
    x: 83,
    y: 31,
    size: 9,
    angle: 90,
  },
  firstName: {
    type: "text",
    label: "Имя",
    x: 37,
    y: 25,
    size: 9,
  },
  lastName: {
    type: "text",
    label: "Фамилия",
    x: 37,
    y: 29,
    size: 9,
  },
  iin: {
    type: "text",
    label: "ИИН",
    x: 43,
    y: 33.6,
    size: 6,
  },
  studyTime: {
    type: "text",
    label: "Время обучения",
    x: 37,
    y: 39,
    size: 6,
  },
  issueDate: {
    type: "text",
    label: "Дата выдачи",
    x: 56.5,
    y: 39,
    size: 6,
  },
  facultyName: {
    type: "text",
    label: "Факультет",
    x: 9,
    y: 40.5,
    size: 6,
  },
  facultyCode: {
    type: "text",
    label: "BBB шифр",
    x: 37,
    y: 46.5,
    size: 6,
  },
  cardNumber: {
    type: "text",
    label: "Номер карты",
    x: 9,
    y: 45,
    size: 6,
  },
  expirationDate: {
    type: "text",
    label: "Дата истечения",
    x: 56.5,
    y: 46.5,
    size: 6,
  },
  localPhotoUri: {
    type: "image",
    label: "Фото",
    path: "photo",
    x: 11,
    y: 13,
    width: 17,
    height: 22.6,
  },
  qrCode: {
    type: "image",
    label: "QR код",
    path: "qr",
    x: 61.2,
    y: 17,
    width: 18,
    height: 18,
  },
}
